<template>
	<div id="setEvaluate">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">评价配置</a></el-breadcrumb-item>
			</el-breadcrumb>
			<!-- <div class="head u-f-item">
				<span class="span">选择条件</span>
				<el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div> -->
			<div class="content">
				<div class="title u-f-item u-f-jsb">
					<div>
						共{{total}}条数据，
						<span style="color: #007AFF;">已选择{{ids.length}}条</span>
					</div>
					<div class="u-f-item">
						<!-- <el-input placeholder="请输入内容" v-model="sousuo" size="small" style="margin-right: 10px;">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-input> -->
						<el-button size="small" @click="openDialog()">添加配置</el-button>
					</div>
				</div>
				<el-table :data="list" height="84%" ref="multipleTable" tooltip-effect="dark" @selection-change="handleSelectionChange" style="width: 100%;border: 1px solid #EEEEEE;">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column type="index" label="序号" width="70"></el-table-column>
					<el-table-column prop="name" label="评价标题"></el-table-column>
					<el-table-column label="性质">
						<template slot-scope="scope">
							<el-tag v-if="scope.row.type==1">{{scope.row.type_text}}</el-tag>
							<el-tag type="success" v-if="scope.row.type==2">{{scope.row.type_text}}</el-tag>
						</template>
					</el-table-column>
					<el-table-column prop="category_text" label="类型"></el-table-column>
					<el-table-column prop="val" label="默认分值"></el-table-column>
					<el-table-column prop="max_val" label="最大分值"></el-table-column>
					<el-table-column label="评价详情">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start">
							  <div>{{scope.row.reason}}</div>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div class="u-f-item u-f-jsb" style="padding: 15px 0;">
					<el-button type="danger" plain size="mini" @click="del">删除</el-button>
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page.sync="page"
						:page-size="limit"
						layout="total, prev, pager, next"
						:total="total"
					></el-pagination>
				</div>
			</div>
			<!-- 添加 -->
			<el-dialog title="添加配置" :visible.sync="addVisible" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							评价名称
						</div>
						<el-input v-model="name" placeholder="请输入评价名称" style="width: 70%;"></el-input>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							评价性质
						</div>
						<el-radio-group v-model="type">
							<el-radio-button :label="1">正面</el-radio-button>
							<el-radio-button :label="2">负面</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							评价类型
						</div>
						<el-radio-group v-model="category">
							<el-radio-button :label="1">巡课</el-radio-button>
							<el-radio-button :label="2">考勤</el-radio-button>
							<el-radio-button :label="3">未完成</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							适用对象
						</div>
						<el-radio-group v-model="aim">
							<el-radio-button :label="1">老师</el-radio-button>
							<el-radio-button :label="2">学生</el-radio-button>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							默认分值
						</div>
						<div @mouseover="mouseOver" @mouseleave="mouseLeave">
							<el-input-number v-model="val" :controls="controls" @change="handleChange" :min="0" :max="10" label="默认分值"></el-input-number>
						</div>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal">
							最大分值
						</div>
						<div @mouseover="mouseOver1" @mouseleave="mouseLeave1">
							<el-input-number v-model="max_val" :controls="controls1" @change="handleChange1" :min="0" :max="10" label="默认分值"></el-input-number>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">
							<span>*</span>
							详情
						</div>
						<el-input type="textarea" rows="3" v-model="reason" placeholder="请输入名称" style="width: 70%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="close" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit()">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			condition: '',
			conditionList: [],
			sousuo: '',
			list:[],
			addVisible:false,
			total: 0,
			page: 1,
			limit: 11,
			name: '',
			ids: [],
			type:1,
			category:1,
			aim:1,
			val:0,
			max_val:0,
			reason:'',
			controls:false,
			controls1:false,
		};
	},
	mounted() {
		this.getList()
	},
	methods: {
		mouseOver(){
			this.controls = true;
		},
		mouseLeave(){
			this.controls = false;
		},
		mouseOver1(){
			this.controls1 = true;
		},
		mouseLeave1(){
			this.controls1 = false;
		},
		del(){
			let _this = this;
			_this.$confirm('确定要删除？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				})
				.then(() => {
					_this.$api.setting
						.delConfog({
							id: this.ids.toString()
						})
						.then(res => {
							if (res.data.code == 1) {
								_this.$message.success('删除成功');
								_this.getList();
							} else {
								_this.$message.error(res.data.msg);
							}
						});
				});
		},
		handleChange(value) {
			console.log(value);
		},
		handleChange1(value) {
			console.log(value);
		},
		// 关闭弹窗
		handleClose (done) {
			this.init()
			done()
		},
		close(){
			this.init()
			this.addVisible = false;
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList()
		},
		openDialog(){
			this.addVisible = true;
		},
		getList(){
			this.$api.setting.evaluationConfigList({
				page:this.page,
				limit:this.limit
			}).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		init(){
			this.name = '';
			this.type = 1;
			this.category = 1;
			this.aim = 1;
			this.val = 0;
			this.max_val = 0;
			this.reason = '';
		},
		submit(){
			let data = {
				name:this.name,
				reason:this.reason,
				// school_campus_id:this.condition,
				type:this.type,
				category:this.category,
				val:this.val,
				max_val:this.max_val,
				aim:this.aim
			}
			this.$api.setting.addConfog(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.init()
					this.getList()
					this.addVisible = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		}
	}
};
</script>

<style lang="scss">
#setEvaluate {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		padding: 0 30px;
		.title {
			padding: 15px 0;
		}
	}
	
	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}
	
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
